import 'bootstrap/js/dist/modal'
import uncoverPassword from '../js/uncover_password.js'
window.uncoverPassword = uncoverPassword
var videoLoadCount = 5;
document.addEventListener("DOMContentLoaded",function(){
	if($("#testimonials").width()>=768){
		$("#testimonial-01").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-1").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-8');
		  $(".t-student-2").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-3").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-4").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-5").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-1 .testimonial-img").addClass("hide-testimonial-img").removeClass("testimonial-active").addClass("testimonial-active");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-1 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-details").removeClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/UKp1FlbUQ0w?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-02").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-2").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-8');
		  $(".t-student-1").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-3").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-4").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-5").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-2 .testimonial-img").addClass("hide-testimonial-img").removeClass("testimonial-active").addClass("testimonial-active");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-2 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-details").removeClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/9M3iPwH6JaA?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-03").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-3").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-8');
		  $(".t-student-1").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-2").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-4").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-5").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-3 .testimonial-img").addClass("hide-testimonial-img").removeClass("testimonial-active").addClass("testimonial-active");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-3 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-details").removeClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/IJCa9_D-Tj4?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-04").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-4").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-8');
		  $(".t-student-1").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-2").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-3").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-5").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-4 .testimonial-img").addClass("hide-testimonial-img").removeClass("testimonial-active").addClass("testimonial-active");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-4 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-details").removeClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/efd0b1zAFR8?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-05").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-5").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-8');
		  $(".t-student-1").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-2").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-3").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-4").removeClass("col-md-5ths").removeClass("col-md-8").removeClass("col-md-1").addClass('col-md-1');
		  $(".t-student-5 .testimonial-img").addClass("hide-testimonial-img").removeClass("testimonial-active").addClass("testimonial-active");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img").removeClass("testimonial-active");
		  $(".t-student-5 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-details").removeClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-details").addClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/Zdwl4ow8DB4?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
	}else{
		$("#testimonial-01").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-1 .testimonial-img").addClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/UKp1FlbUQ0w?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-02").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-2 .testimonial-img").addClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/9M3iPwH6JaA?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-03").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-3 .testimonial-img").addClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/IJCa9_D-Tj4?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-04").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-4 .testimonial-img").addClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/efd0b1zAFR8?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
		$("#testimonial-05").click(function(){
		  $("#ytplayer").remove();
		  $(".t-student-5 .testimonial-img").addClass("hide-testimonial-img");
		  $(".t-student-1 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-2 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-3 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-4 .testimonial-img").removeClass("hide-testimonial-img");
		  $(".t-student-5 .playicon").addClass("hide-testimonial-img");
		  $(".t-student-1 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-2 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-3 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-4 .playicon").removeClass("hide-testimonial-img");
		  $(".t-student-5 .testimonial-container").append("<iframe id='ytplayer' width='640' height='360' src='https://www.youtube.com/embed/Zdwl4ow8DB4?autoplay=1&playsinline=1&modestbranding=1&origin=https://scholarships2u.com' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'>");
		});
	}

	if($(".container").width()>=768){
		$(".s2u-step-1").addClass("animatable").addClass("fadeInUp").addClass("cdelay-2");
		$(".s2u-step-2").addClass("animatable").addClass("fadeInUp").addClass("cdelay-3");
		$(".s2u-step-3").addClass("animatable").addClass("fadeInUp").addClass("cdelay-4");
		$(".s2u-step-4").addClass("animatable").addClass("fadeInUp").addClass("cdelay-5");
		$(".s2u-step-5").addClass("animatable").addClass("fadeInUp").addClass("cdelay-6");
	}else{
		$(".s2u-step-1").addClass("animatable").addClass("fadeIn");
		$(".s2u-step-2").addClass("animatable").addClass("fadeIn");
		$(".s2u-step-3").addClass("animatable").addClass("fadeIn");
		$(".s2u-step-4").addClass("animatable").addClass("fadeIn");
		$(".s2u-step-5").addClass("animatable").addClass("fadeIn");
	}

	/**if($(".container").width()>=768){
		$("#banner-video-container").append("<stream src='97703b5c51b58fae2b3970b9cc7a6679' preload autoplay controls loop muted class='banner-video'></stream><script data-cfasync='false' defer type='text/javascript' src='https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=97703b5c51b58fae2b3970b9cc7a6679'></script>");
	}else{
		$("#banner-video-container").append("<stream src='3b68b9a51b7b0672c6bfaac3331151bd' preload autoplay controls loop muted class='banner-video-square'></stream><script data-cfasync='false' defer type='text/javascript' src='https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=3b68b9a51b7b0672c6bfaac3331151bd'></script>");
		checkVideo();
	}**/

	$("#banner-video-container").append("<stream src='03ae2b927f2471e35ccd60eb9dd86e07' preload autoplay controls loop muted class='banner-video'></stream><script data-cfasync='false' defer type='text/javascript' src='https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=03ae2b927f2471e35ccd60eb9dd86e07'></script>");

	function checkVideo() {
		if ( $( "#banner-video-container video" ).length ) {
		  $('#banner-video-container video')[0].play();
		  clearTimeout();
		}else{
			videoLoadCount = videoLoadCount - 1;
			if ( videoLoadCount > 1) {setTimeout(checkVideo, 1000);}
		}
	}

	$("#ace-video-container").append("<stream src='53efd23afd0cf57147a1b6d91f2ed5f9' preload autoplay controls loop muted class='banner-video'></stream><script data-cfasync='false' defer type='text/javascript' src='https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=53efd23afd0cf57147a1b6d91f2ed5f9'></script>");

	function checkVideo() {
		if ( $( "#ace-video-container video" ).length ) {
		  $('#ace-video-container video')[0].play();
		  clearTimeout();
		}else{
			videoLoadCount = videoLoadCount - 1;
			if ( videoLoadCount > 1) {setTimeout(checkVideo, 1000);}
		}
	}

	/** $("#mute-video").click( function (){
		if( $("#banner-video-container video").prop('muted') ) {
		    $("#banner-video-container video").prop('muted', false);
		    $("#mute-video .fa-volume-mute").addClass("d-none").removeClass("d-block");
		  	$("#mute-video .fa-volume-up").addClass("d-block").removeClass("d-none");

		} else {
			$("#banner-video-container video").prop('muted', true);
			$("#mute-video .fa-volume-mute").removeClass("d-none").addClass("d-block");
			$("#mute-video .fa-volume-up").removeClass("d-block").addClass("d-none");
		}
	});

	$("#play-video").click( function (){
		$('#banner-video-container video').get(0).play();
		$("#play-video").addClass("d-none").removeClass("d-inline-block");
		$("#pause-video").removeClass("d-none").addClass("d-inline-block");
	});

	$("#pause-video").click( function (){
		$('#banner-video-container video').get(0).pause();
		$("#pause-video").addClass("d-none").removeClass("d-inline-block");
		$("#play-video").removeClass("d-none").addClass("d-inline-block");
	}); **/

    var header = $(".homenav");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 100) {
            header.removeClass('navbar-transparent').addClass("navbar-light");
        } else {
            header.removeClass("navbar-light").addClass('navbar-transparent');
        }

        if (scroll >= 640) {
           $(".banner-cta").removeClass("banner-depth");
        } else {
            $(".banner-cta").addClass("banner-depth");
        }
    });

	$('.count').each(function () {
	    $(this).prop('Counter',0).animate({
	        Counter: $(this).text()
	    }, {
	        duration: 3000,
	        easing: 'swing',
	        step: function (now) {
	            $(this).text(Math.ceil(now).toLocaleString('en'));
	        }
	    });
	});

	jQuery(function($) {

	  // Function which adds the 'animated' class to any '.animatable' in view
	  var doAnimations = function() {

	    // Calc current offset and get all animatables
	    var offset = $(window).scrollTop() + $(window).height(),
	        $animatables = $('.animatable');

	    // Unbind scroll handler if we have no animatables
	    if ($animatables.length == 0) {
	      $(window).off('scroll', doAnimations);
	    }

	    // Check all animatables and animate them if necessary
			$animatables.each(function(i) {
	       var $animatable = $(this);
				if (($animatable.offset().top + $animatable.height() - 20) < offset) {
	        $animatable.removeClass('animatable').addClass('animated');
				}
	    });

		};

	  // Hook doAnimations on scroll, and trigger a scroll
		$(window).on('scroll', doAnimations);
	  $(window).trigger('scroll');

	});

	// REMOVE the modal to bonanza as bonanza finish already UNITL FURTHER NOTICE.
	// if (document.cookie.indexOf('landing_modal_shown=') >= 0) {
  //  //do nothing if modal_shown cookie is present
  // } else {
  //   $('#popup').modal('show');  //show modal pop up
  //   document.cookie = 'landing_modal_shown=seen'; //set cookie modal_shown
  //   //cookie will expire when browser is closed
  // }

});
