function uncoverPassword(element) {
	var selected_password_field = $(element).prev()[0];
	$(".password_field").each(function(){
		if(this != selected_password_field){
			$(this)[0].type = "password"
		}
	});
	
	if ($(selected_password_field).val().length != 0){
	  if (selected_password_field.type === "password") {
	    selected_password_field.type = "text";
	  } else {
	    selected_password_field.type = "password";
	  }
	}
}

export default uncoverPassword;